import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-scroll";
import {NavLink as RouterLink} from "react-router-dom"
import cn from 'classnames';

import './Nav.scss';

const Nav = ({textColor, isOpen}) => {
  const isVacanciesActive = () => {
    const currentLocation = window.location.href.split('/')[window.location.href.split('/').length-1]
    return currentLocation === 'vacancies'
  }

  return (
    <nav className={cn("nav", {"nav_open": isOpen})} style={{color: textColor}}>
      <ul className="nav-list">
        <li className="nav-list__item">
          {isVacanciesActive() ? <RouterLink className='router-link' to="/#equipment">Equipment</RouterLink> : <Link
            activeClass="active"
            to="Equipment"
            spy={true}
            smooth={true}
            duration={500}
          >Equipment</Link>}
        </li>
        <li className="nav-list__item">
          {isVacanciesActive() ? <RouterLink className='router-link' to='/#about-us'>About Us</RouterLink> : <Link
            activeClass="active"
            to="About Us"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
          >About Us</Link>}
        </li>
        <li className="nav-list__item">
          {isVacanciesActive() ? <RouterLink className='router-link' to='/#services'>Services</RouterLink> : <Link
            activeClass="active"
            to="Services"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
          >Services</Link>}
        </li>
        <li className="nav-list__item">
          <RouterLink
            className='router-link'
            to='/vacancies'
          >Vacancies</RouterLink>
        </li>
        <li className="nav-list__item">
          {isVacanciesActive() ? <RouterLink className='router-link' to='/#contact-us'>Contact Us</RouterLink> : <Link
            activeClass="active"
            to="Contact Us"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
          >Contact Us</Link>}
        </li>
      </ul>
    </nav>
  );
};

Nav.propTypes = {
  textColor: PropTypes.string,
  isOpen: PropTypes.bool
};

export default Nav;