import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import leftArrowSvg from '../../assets/left-arrow.svg';
import rightArrowSvg from '../../assets/right-arrow.svg';
import './ImgGallery.scss';

const ImgGallery = ({images}) => {
    
    const renderLeftNav = (onClick, disabled) => {
        return (
            <button
            className='image-gallery-custom-left-nav'
            disabled={disabled}
            onClick={onClick}> <img src={leftArrowSvg} alt="left-arrow"/> </button>
        )
    }
    const renderRightNav = (onClick, disabled) => {
        return (
            <button
            className='image-gallery-custom-right-nav'
            disabled={disabled}
            onClick={onClick}> <img src={rightArrowSvg} alt="right-arrow"/> </button>
        )
    }
    
    return (
        <ImageGallery 
            renderLeftNav={renderLeftNav} 
            renderRightNav={renderRightNav} 
            items={images} 
            sizes={100}
            showPlayButton={false}
            showFullscreenButton={false}
        />
    );
};

ImgGallery.propTypes = {
    images: PropTypes.array
};

export default ImgGallery;