import React from 'react';
import styles from './VacancyItem.module.scss'
import location from '../../assets/location.svg'
import schedule from '../../assets/schedule.svg'
import salary from '../../assets/salary.svg'

function VacancyItem({vacancy}) {
  return (
    <div className={styles.vacancyCard}>
      <h2>{vacancy.title}</h2>

      <div className={styles.additional}>
        <p><img src={salary} alt="salary"/>{vacancy.salary}</p>
        <p><img src={schedule} alt="schedule"/>{vacancy.schedule}</p>
        <p><img src={location} alt="location"/>{vacancy.location}</p>
      </div>

      <h3>{vacancy.firstBlockTitle}</h3>
      <p>{vacancy.firstBlockText}</p>
      <h3>{vacancy.secondBlockTitle}</h3>
      <p>{vacancy.secondBlockText}</p>

      <a href="mailto:alpha.borjghali@zelenski.us">Apply</a>

    </div>
  );
}

export default VacancyItem;