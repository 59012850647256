import React from 'react';
import PropTypes from 'prop-types';
import closeSvg from '../../assets/close.svg';
import './Modal.scss';

const Modal = ({children, onClick}) => {
    
    return (
        <div className="modal">
           <div className="modal-content">
               <button onClick={onClick} className="modal-content__close"><img src={closeSvg} alt="close icon"/></button>
                {children}
           </div>
        </div>
    );
};

Modal.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired
};

export default Modal;