import React from 'react';
import TentsPng from '../../assets/equipment-1.png';
import toiletPng from '../../assets/equipment-2.png';
import transportPng from '../../assets/equipment-3.png';
import bunksPng from '../../assets/equipment-4.png';
import electroPng from '../../assets/equipment-5.png';
import eatPng from '../../assets/equipment-6.png';
import {tents, toilets, generators, bunks, electro, eat} from '../../galleryImages';
import './Equipment.scss';
const Equipment = ({onClick}) => {
    
    return (
        <div className="equipment-wrapper">
            <div onClick={() => onClick(tents)}
                className="equipment__item" 
                style={{backgroundImage: `url(${TentsPng})` }} 
            />
            <div onClick={() => onClick(toilets)}
                className="equipment__item" 
                style={{backgroundImage: `url(${toiletPng})` }} 
            />
            <div onClick={() => onClick(generators)}
                className="equipment__item" 
                style={{backgroundImage: `url(${transportPng})` }} 
            />
            <div onClick={() => onClick(bunks)}
                className="equipment__item" 
                style={{backgroundImage: `url(${bunksPng})` }} 
            />
            <div onClick={() => onClick(electro)}
                className="equipment__item" 
                style={{backgroundImage: `url(${electroPng})` }} 
            />
            <div onClick={() => onClick(eat)}
                className="equipment__item" 
                style={{backgroundImage: `url(${eatPng})` }} 
            />
        </div>
    );
};

export default Equipment;