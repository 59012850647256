import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './Burger.scss';

const Burger = ({onClick, isOpen}) => {
    return (
        <div onClick={onClick} className={cn("burger", {"burger_active": isOpen})} />
    );
};

Burger.propTypes = {
    onClick: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default Burger;