export const tents = [
    {
      original: 'http://s-torg.com.ua/files/39-276-77.jpg',
      thumbnail: 'http://s-torg.com.ua/files/39-276-77.jpg',
    },
    {
      original: 'https://regnum.ru/uploads/pictures/news/2019/07/24/regnum_picture_15639374471601352_normal.jpg',
      thumbnail: 'https://regnum.ru/uploads/pictures/news/2019/07/24/regnum_picture_15639374471601352_normal.jpg',
    },
    {
      original: 'https://images.la.lv/uploads/2016/06/nojume_54982090.jpg',
      thumbnail: 'https://images.la.lv/uploads/2016/06/nojume_54982090.jpg',
    },
];

export const toilets = [
    {
      original: 'https://zsmod.ru/wp-content/uploads/2018/04/xcpnZ0_tqYo.jpg',
      thumbnail: 'https://zsmod.ru/wp-content/uploads/2018/04/xcpnZ0_tqYo.jpg',
    },
    {
      original: 'https://api.parkseason.ru/images/styles/1440_auto/52/87/f3ccdd27d2000e3f9255a7e3e2c488005984918b74941153557616.jpg',
      thumbnail: 'https://api.parkseason.ru/images/styles/1440_auto/52/87/f3ccdd27d2000e3f9255a7e3e2c488005984918b74941153557616.jpg',
    },
    {
        original: 'https://ua.all.biz/img/ua/catalog/1535561.jpeg',
        thumbnail: 'https://ua.all.biz/img/ua/catalog/1535561.jpeg',
    },
];

export const generators = [
    {
      original: 'https://images.ua.prom.st/211049101_211049101.jpg?PIMAGE_ID=211049101',
      thumbnail: 'https://images.ua.prom.st/211049101_211049101.jpg?PIMAGE_ID=211049101',
    },
    {
      original: 'http://grabelki.com.ua/wp-content/uploads/2017/06/dsc_043-660x440.jpg',
      thumbnail: 'http://grabelki.com.ua/wp-content/uploads/2017/06/dsc_043-660x440.jpg',
    },
    {
      original: 'https://fdlx.com/wp-content/uploads/generator-0504.jpg',
      thumbnail: 'https://fdlx.com/wp-content/uploads/generator-0504.jpg',
    },
];

export const bunks = [
    {
        original: 'https://images.kz.prom.st/84479034_w640_h640_raskladushka-drema-4-bolshaya.jpg',
        thumbnail: 'https://images.kz.prom.st/84479034_w640_h640_raskladushka-drema-4-bolshaya.jpg',
    },
    {
        original: 'http://zamyatin.club/wp-content/uploads/2019/07/%D0%9A%D1%80%D0%BE%D0%B2%D0%B0%D1%82%D1%8C-%D1%80%D0%B0%D1%81%D0%BA%D0%BB%D0%B0%D0%B4%D1%83%D1%88%D0%BA%D0%B0-%D0%93%D1%80%D0%B8%D0%B7%D0%BB%D0%B8.jpg',
        thumbnail: 'http://zamyatin.club/wp-content/uploads/2019/07/%D0%9A%D1%80%D0%BE%D0%B2%D0%B0%D1%82%D1%8C-%D1%80%D0%B0%D1%81%D0%BA%D0%BB%D0%B0%D0%B4%D1%83%D1%88%D0%BA%D0%B0-%D0%93%D1%80%D0%B8%D0%B7%D0%BB%D0%B8.jpg',
    },
]
export const electro = [
    {
        original: 'https://2.allegroimg.com/s1440/032852/f96ae71b4a82812a0bbfeac0cd72',
        thumbnail: 'https://2.allegroimg.com/s1440/032852/f96ae71b4a82812a0bbfeac0cd72',
    }, 
]

export const eat = [
    {
        original: 'https://suvenir.bg/upload/46358-(3).jpg',
        thumbnail: 'https://suvenir.bg/upload/46358-(3).jpg',
    }, 
    {
        original: 'https://images.kz.prom.st/45059620_raskladnoj-stol-i.jpg',
        thumbnail: 'https://images.kz.prom.st/45059620_raskladnoj-stol-i.jpg',
    }, 
    {
        original: 'https://s12emagst.akamaized.net/products/19650/19649071/images/res_f3033440e86a186ed42317e6f6c5748b_full.jpg',
        thumbnail: 'https://s12emagst.akamaized.net/products/19650/19649071/images/res_f3033440e86a186ed42317e6f6c5748b_full.jpg',
    }, 
]