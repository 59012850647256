import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Landing from "../Landing/Landing";
import Vacancies from "../Vacancies/Vacancies";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<Landing />}/>
        <Route exact path='/vacancies' element={<Vacancies />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;