import React from 'react';
import PropTypes from 'prop-types';
import './Alert.scss';
const Alert = ({children, color}) => {
    return (
        <div style={{backgroundColor: color}} className="alert">{children}</div>
    );
};

Alert.propTypes = {
    children: PropTypes.node.isRequired
};

export default Alert;