import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Burger from '../Burger/Burger';
import Logo from '../Logo/Logo';
import NavMobile from '../Nav/NavMobile';

const HeaderMobile = ({burgerClickHandler, isOpen}) => {
    return (
        <header className={cn("header", {"header-mob_open": isOpen})}>
            <div className="container header-wrapper">
                <Logo />
                <Burger isOpen={isOpen} onClick={burgerClickHandler} />
                { isOpen && <NavMobile onClick={burgerClickHandler} isOpen={isOpen} textColor="#FFFFFF" /> }
            </div>
        </header>   
    )
}

HeaderMobile.propTypes = {
    burgerClickHandler: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default HeaderMobile;