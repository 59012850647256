const API_BASE = 'https://api.argentum.travel/api/';

async function postData(url, data = {}) {
    const response = await fetch(url, {
      method: 'POST', 
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      },
    })    
    
    return await response.json(); 
};

export async function postFeedbackForm(data) {
    return await postData(API_BASE + "borjghali/", data);
}