import React, {useState} from 'react';

import Header from '../Header/Header';
import HoverSlider from '../HoverSlider/HoverSlider';
import AboutUs from '../AboutUs/AboutUs';
import Equipment from '../Equipment/Equipment';
import Services from '../Services/Services';
import Footer from '../Footer/Footer';
import FeedbackForm from '../FeedbackForm/FeedbackForm';
import Alert from '../Alert/Alert';
import Modal from '../Modal/Modal';
import ImgGallery from '../ImgGallery/ImgGallery';

import './Landing.scss';
import {scroller} from "react-scroll";

function Landing() {
  const [alert, setAlert] = useState({
    isActive: false,
    color: '#313131',
    message: 'Thank you! Your submission has been received.'
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [galleryContent, setGalleryContent] = useState([]);
  const showAlert = (color, message) => {
    setAlert({
      isActive: true,
      color, message
    })
    setTimeout(() => {
      setAlert({
        ...alert,
        isActive: true
      })
    }, 4000)
  }

  const closeModal = () => {
    setIsModalOpen(false);
  }
  const openModal = (images) => {
    setIsModalOpen(true);
    setGalleryContent(images);
  }

  const scrollToCustomFunc = () => {
    const currentLocation = window.location.href.split('/')[window.location.href.split('/').length - 1]
    if (currentLocation === "#services") {
      scroller.scrollTo('Services', {duration: 500, smooth: 'easeInOutQuart'})
    } else if (currentLocation === "#contact-us") {
      scroller.scrollTo('Contact Us', {duration: 500, smooth: 'easeInOutQuart'})
    } else if (currentLocation === "#about-us") {
      scroller.scrollTo('About Us', {duration: 500, smooth: 'easeInOutQuart'})
    } else if (currentLocation === "#equipment") {
      scroller.scrollTo('Equipment', {duration: 500, smooth: 'easeInOutQuart'})
    }
  }

  React.useEffect(() => {
    scrollToCustomFunc()
  },[])

  return (
    <React.Fragment>

      {isModalOpen && (
        <Modal onClick={closeModal}>
          <ImgGallery images={galleryContent}/>
        </Modal>
      )}

      {alert.isActive && (
        <Alert color={alert.color}>{alert.message}</Alert>
      )}
      <Header/>
      <section name="Home" className="main-section">
        <HoverSlider/>
      </section>

      <section name="Equipment" className="content-section">
        <div className="container">
          <h3 className="title title-equipment">Our Equipment</h3>
          <Equipment onClick={openModal}/>
        </div>
      </section>

      <AboutUs/>
      <section name="Services" className="content-section" id='services'>
        <h3 className="title section-title title-services">Our Services</h3>
        <Services/>
      </section>
      <section name="Contact Us" className="content-section" id='contact-us'>
        <div className="container">
          <div className="title feedback-form-title">Order the Service</div>
          <FeedbackForm showAlert={showAlert}/>
        </div>
      </section>
      <Footer/>

    </React.Fragment>
  );
}

export default Landing;
