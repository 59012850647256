import React from 'react';
import { Link } from "react-scroll";
import Button from '../Button/Button';

const AboutUs = () => {
    return (
        <section name="About Us" className="content-section">
        <div className="container">
          <div className="about-us">
            <h3 className="title about-us__title">Alpha Borjghali</h3>
            <div className="about-us__description">
              For many years, ALPHA BORJHALI has been providing services in providing the material base of equipment for 
              conducting large-scale annual military exercises in Georgia and in other countries.
              The company also provides equipment for scientific forums, exhibitions, commercial presentations,
              trainings, sporting events, outdoor events and concerts.
              We are ready to help you with the following questions:
              preliminary consultations;
              examination by a specialist of the place of the future event;
              detailed cost calculation for these services and equipment;
              delivery and transportation of the necessary devices.
            </div>
            <Link
              to="Contact Us"
              spy={true}
              smooth={true}
              offset={-200}
              duration={500}
            ><Button size="sm">Contact Us</Button></Link> 
          </div>
        </div>
      </section>
    );
};

export default AboutUs;