import React from 'react';
import Header from "../Header/Header";
import styles from "./Vacancies.module.scss"
import VacancyItem from "../VacancyItem/VacancyItem";
import Footer from "../Footer/Footer";
import {Link} from "react-scroll";

const vacancies = [
  {
    title: "HeadHunter",
    firstBlockTitle: "Main responsibilities:",
    firstBlockText: "Working as Client Support center\n" +
      "Working with Data base, upload documents and electronic record amendment",
    secondBlockTitle: "Required skills:",
    secondBlockText: "Excellent communication skills in Georgian, English, and Russian language.\n" +
      "Strong negotiating skills\n" +
      "Excellent communication, organizational and grammatical skills.",
    salary: "Depend on Experience",
    schedule: "Mon to Fri, 9:00 - 18:00",
    location: "Georgia, Batumi",
  },
]

function Vacancies() {

  React.useEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <>
      <Header/>
      <div className={styles.hero}>
        <div className={styles.heroText}>
          <h1>Vacancies</h1>
          <p>Alpha Borjghali is an international manning company and looking for a good staff to take care about our
            electronic data base and keep contact with our clients.</p>
          <Link smooth={true} duration={500} to={"Vacancies"}>View vacancies</Link>
        </div>
      </div>
      <div className="container">
        <div className={styles.vacancies} name={"Vacancies"}>
        {vacancies.map((vacancy, index) => (
          <VacancyItem vacancy={vacancy} key={index}/>
        ))}
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default Vacancies;