import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';
const Button = ({children, disabled = false, size = "sm"}) => {
    return (
        <button disabled={disabled} className={`btn btn-${size}`}>
            {children}
        </button>
    );
};

Button.propTypes = {
    children: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    size: PropTypes.string
};

export default Button;