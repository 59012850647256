import React from 'react';
import './Services.scss';

import portAgencyPng from '../../assets/service-1.png';
import commercialPng from '../../assets/service-2.png';
import trainingPng from '../../assets/service-3.png';
import hostingPng from '../../assets/service-4.png';
const Services = () => {
    return (
        <div className="services">
            <div className="services-item">
                <div className="services-item__bg" style={{backgroundImage: `url(${portAgencyPng})` }} />
                <div className="services-item__title">
                    Port Agency
                </div>
                <div className="services-item__description">
                    Over the years, our company has provide itself to have the flexibility, experience and knowledge to
                    successfully address all needs of the ship owners in agency service at ports of Black Sea.
                    On a 24/7 schedule we are ready to ensure all port services, any type of provision, crew changes
                    and force protection.
                </div>
            </div>

            <div className="services-item">
                <div className="services-item__bg" style={{backgroundImage: `url(${commercialPng})` }} />
                <div className="services-item__title">
                    Commercial Logistics
                </div>
                <div className="services-item__description">
                    In cooperation with leading transport companies we provide highly
                    demanded forwarding and logistics services.
                    Our specialists will be happy to help develop new routes and schemes for
                    transporting your cargo, optimizing the time and cost of transportation, which
                    is the guarantor of the successful conduct of your business.
                </div>
            </div>

            <div className="services-item  services-item_large">
                <div className="services-item__bg" style={{backgroundImage: `url(${trainingPng})` }} />
                <div className="services-item__title services-item__title">
                    Military Exercises and Trainings Procerement
                </div>
                <div className="services-item__description">
                    We are supporting military exercises involving land, naval and air forces, providing logistical
                    requirements , arrangement and support of the field camps, husbanding and agency of navy ships,
                    delivering fuel, foodstuffs, goods provision and all types of equipment rent.
                </div>
            </div>

            <div className="services-item">
                <div className="services-item__bg" style={{backgroundImage: `url(${hostingPng})` }} />
                <div className="services-item__title">
                    International Events Hosting
                </div>
                <div className="services-item__description">
                    Our company offers a full range of services for the organization of international
                    events like exhibitions, conferences, seminars, regardless of their subject matter and location.
                </div>
            </div>

        </div>
    );
};

export default Services;