import React from 'react';
import './Logo.scss';
import logoSvg from '../../assets/logo.svg';
const Logo = props => {
    return (
       <a href="/">
           <img src={logoSvg} alt="logo"/>
       </a>
    );
};


export default Logo;