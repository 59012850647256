import React, { useState } from 'react';
import { postFeedbackForm } from '../../services/apiService';
import './FeedbackForm.scss';
import Button from '../Button/Button';
import Select from '../Select/Select';
const FeedbackForm = ({showAlert}) => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        type: 'Services',
        message: ''
    });
    const [isFormSended, setIsFormSended] = useState(false);
    const [isSelectValid, setSelectValid] = useState(true);

    const handleSelect = (value) => {
        setSelectValid(true);
        setFormData({
            ...formData,
            type: value
        })
    }
    
    const handleSubmit = (event) => {
        event.preventDefault()
        if(formData.type !== 'Services') {
            setIsFormSended(true);
            postFeedbackForm(formData).then(() => {
                showAlert('#313131', 'Thank you! Your submission has been received.')
            }).catch(e => {
                showAlert('tomato', 'Oops! Something went wrong.');
                console.error(e);
            });

        } else if(formData.type === 'Services') {
            setSelectValid(false)
        }
        
    }
    const handleInputBlur = ({target}) => {
        setFormData({
            ...formData,
            [target.name]: target.value
        })
    }
    return (
        <form className="feedback-form" onSubmit={handleSubmit} >
            <input placeholder="Name" onBlur={handleInputBlur}  required min={3} className="feedback-form__item" type="text" name="name" />
            <input placeholder="Phone" onBlur={handleInputBlur} required pattern="([+(\d]{1})(([\d+() -.]){5,16})([+(\d]{1})" className="feedback-form__item" type="tel" name="phone" />
            <input placeholder="Email" onBlur={handleInputBlur} required min={3} className="feedback-form__item" type="email" name="email" />
            <Select 
                selectedItem={formData.type} 
                onClick={handleSelect} 
                isValid={isSelectValid}
                items={["Port Agency",  "Commercial Logistics", "Military Exercises and Trainings Procerement", "International Events Hosting"]}    
            />
            <input placeholder="How we can help you?" onBlur={handleInputBlur} className="feedback-form__item" type="text" name="message" />
            <Button disabled={isFormSended} size="sm">Order</Button>
        </form>
    );
};

export default FeedbackForm;