import React, { useState } from 'react';
import Logo from '../Logo/Logo';
import Nav from '../Nav/Nav';
import HeaderMobile from './HeaderMobile';

import './Header.scss';

const Header = props => {
    const isMobile = (window.innerWidth <= 770);

    const [isOpen, setIsOpen] = useState(false);
    
    const burgerClickHandler = () => {
        setIsOpen(() => !isOpen);
        isMobile && (document.body.style.overflow = isOpen ? 'unset' : 'hidden');
    }

    return (
        <React.Fragment>
            {!isMobile ? (
                <header className="header">
                    <div className="container header-wrapper">
                        <Logo />
                        <Nav textColor="#FFFFFF" />
                    </div>
                </header>
            ) : (
                <HeaderMobile burgerClickHandler={burgerClickHandler} isOpen={isOpen} />
            )}
        </React.Fragment>
    );
};

export default Header;