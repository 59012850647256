import React from 'react';
import PropTypes from 'prop-types';

const SelectItem = ({onClick, name}) => {
    return (
            <li className="select-list-item" name={name} 
                onClick={onClick}>
                    {name}
            </li>
    );
};

SelectItem.propTypes = {
    onClick: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired
};

export default SelectItem;