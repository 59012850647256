import React, { useState } from 'react';
import cn from 'classnames';
import { Link } from "react-scroll";

import Button from '../Button/Button';
import './HoverSlider.scss';
import bottomArrowSvg from '../../assets/bottom-main-arrow.svg';
import sliderImageJpg from '../../assets/slider-img.jpg';
import sliderImageSecJpg from '../../assets/slider-img-second.jpg';
import sliderImageThrJpg from '../../assets/slider-img-third.jpg';
import sliderImageFirthJpg from '../../assets/slider-img-firth.jpg';
import sliderImageFifthJpg from '../../assets/slider-img-fifth.jpg';
const HoverSlider = () => {
    const [activeItem, setActiveItem] = useState(1);

    const changeActiveItem = ({target}) => {
        const name = target.dataset.index;
        if(name) {
            setActiveItem(parseInt(name));
        }
    }

    const handleControlClick = ({target}) => {
        const button = target.getAttribute('id');
        button === 'prev' ? 
            setActiveItem(() => activeItem - 1) :
            setActiveItem(() => activeItem + 1);
    }

    const isClose = (itemIndex, currentActive) => {
        return currentActive === itemIndex - 1 || currentActive === itemIndex + 1;
    }

    return (
        <div className="hover-slider-wrapper">
            <Link
                to="Equipment"
                spy={true}
                smooth={true}
                duration={500}
            >
                <button className="hover-slider__scroll-down-btn">
                    Scroll Down
                    <img src={bottomArrowSvg} alt="bottom arrow"/>
                </button>
            </Link> 
            
            <div className="hover-slider">
                <div onClick={changeActiveItem} data-index={1} id="slider-1"
                    className={cn("hover-slider-item", {"hover-slider-item_active": activeItem === 1, "hover-slider-item_close": isClose(1, activeItem)})}
                    style={{backgroundImage: `url(${sliderImageJpg})`}}
                    >
                        <div className="hover-slider-item__content">
                            <h1 className="hover-slider-item__title">EQUIPMENT RENT</h1>
                            <div className="hover-slider-item__subtitle">
                                Being part of AlphaNav Tech since 1997, we are one of leader in services providing
                                the material base of equipment for conducting large-scale annual military exercises
                                in Georgia, Ukraine and countries of Black Sea.
                            </div>
                            <Link
                                to="Equipment"
                                spy={true}
                                smooth={true}
                                duration={500}
                            ><Button size="md">Show all Equipment</Button></Link>
                        </div>
                </div>
                <div onClick={changeActiveItem} data-index={2} id="slider-2"
                    className={cn("hover-slider-item", {"hover-slider-item_active": activeItem === 2, "hover-slider-item_close": isClose(2, activeItem)})}
                    style={{backgroundImage: `url(${sliderImageSecJpg})`}}
                    >
                        <div className="hover-slider-item__content">
                            <h2 className="hover-slider-item__title">PORT AGENCY</h2>
                            <div className="hover-slider-item__subtitle">
                                Lover the years, our company has provide itself to have the flexibility, experience 
                                and knowledge to successfully address all needs of the ship owners in agency service 
                                at ports of Black Sea. On a 24/7 schedule we are ready to ensure all port services, 
                                any type of provision, crew changes and force protection.
                            </div>
                            <Link
                                to="Contact Us"
                                spy={true}
                                smooth={true}
                                offset={-170}
                                duration={500}
                            ><Button size="md">Contact Us</Button></Link>
                        </div>
                </div>
                <div onClick={changeActiveItem} data-index={3} id="slider-3"
                    className={cn("hover-slider-item", {"hover-slider-item_active": activeItem === 3, "hover-slider-item_close": isClose(3, activeItem)})}
                    style={{backgroundImage: `url(${sliderImageThrJpg})`}}
                    >
                        <div className="hover-slider-item__content">
                            <h2 className="hover-slider-item__title">Commercial Logistics</h2>
                            <div className="hover-slider-item__subtitle">
                                In cooperation with leading transport companies we provide highly demanded forwarding and logistics 
                                services. Our specialists will be happy to help develop new routes and schemes for transporting your 
                                cargo, optimizing the time and cost of transportation, which is the guarantor of the successful conduct 
                                of your business.
                            </div>
                            <Link
                                to="Contact Us"
                                spy={true}
                                smooth={true}
                                offset={-170}
                                duration={500}
                            ><Button size="md">Contact Us</Button></Link>
                        </div>
                </div>
                <div onClick={changeActiveItem} data-index={4} id="slider-4"
                    className={cn("hover-slider-item", {"hover-slider-item_active": activeItem === 4, "hover-slider-item_close": isClose(4, activeItem)})}
                    style={{backgroundImage: `url(${sliderImageFirthJpg})`}}
                    >
                        <div className="hover-slider-item__content">
                            <h2 className="hover-slider-item__title">Military Exercises and Trainings Procerement</h2>
                            <div className="hover-slider-item__subtitle">
                                We are supporting military exercises involving land, naval and air forces, providing logistical requirements,
                                arrangement and support of the field camps, husbanding and agency of navy ships, delivering fuel, foodstuffs,
                                goods provision and all types of equipment rent.
                            </div>
                            <Link
                                to="Contact Us"
                                spy={true}
                                smooth={true}
                                offset={-170}
                                duration={500}
                            ><Button size="md">Contact Us</Button></Link>
                        </div>
                </div>
                <div onClick={changeActiveItem} data-index={5} id="slider-4"
                    className={cn("hover-slider-item", {"hover-slider-item_active": activeItem === 5, "hover-slider-item_close": isClose(5, activeItem)})}
                    style={{backgroundImage: `url(${sliderImageFifthJpg})`}}
                    >
                        <div className="hover-slider-item__content">
                            <h2 className="hover-slider-item__title">International Events Hosting</h2>
                            <div className="hover-slider-item__subtitle">
                                Our company offers a full range of services for the organization of international events like exhibitions,
                                conferences, seminars, regardless of their subject matter and location.
                            </div>
                            <Link
                                to="Contact Us"
                                spy={true}
                                smooth={true}
                                offset={-170}
                                duration={500}
                            ><Button size="md">Contact Us</Button></Link>
                        </div>
                </div>
            </div>
            <div className="hover-slider-controls">
                {activeItem > 1 && (<button onClick={handleControlClick} id="prev" className="hover-slider-controls__prev"></button>)}
                {activeItem < 5 && (<button onClick={handleControlClick} id="next" className="hover-slider-controls__next"></button>)}
            </div>
        </div>
   );
};


export default HoverSlider;