import React from 'react';
import './Footer.scss';
import Nav from '../Nav/Nav';
const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <Nav textColor="#ffffff" />
                <div className="contacts">
                    <address className="contacts__address">
                        Alpha Borjghali LLC<br/>
                        Khakhuli II Lane, N75<br/>
                        Batumi, 6000 Georgia<br/>
                    </address>
                    <div className="contacts__phone">
                        <a href="tel:+995593987174">+995 593 98 71 74</a>
                    </div>
                    <div className="contacts__mail">
                        <a href="mailto:alpha.borjghali@zelenski.us">alpha.borjghali@zelenski.us</a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;