import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './Select.scss';
import BottomArrowSvg from '../Icons/BottomArrowSvg';
import SelectItem from './SelectItem';
const Select = ({selectedItem, onClick, isValid, items}) => {
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const selectEl = useRef(null);
  
    useEffect(() => {
        if(selectEl.current) {
            document.addEventListener('click', handleClickOutside, false);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, false);
        }
    }, [])

    const handleItemClick = ({target}) => {
        setIsSelectOpen(false);
        onClick(target.getAttribute('name'));
    } 

    const handleClickOutside = ({target}) => {
        if (!selectEl.current.contains(target)) {
            setIsSelectOpen(false);
        }
    }
  
    return (
        <div ref={selectEl} className={cn("select-wrapper", {"select-wrapper_invalid": !isValid})}>
            <div className="select-header">
                <div className="select-header-title" 
                    onClick={() => setIsSelectOpen(!isSelectOpen)}>
                        {selectedItem}
                </div>
            </div>
            {isSelectOpen && (
                <ul className="select-list">
                    {items.map((item, index) => {
                        return (
                            <SelectItem 
                                onClick={handleItemClick} 
                                name={item} key={index} 
                            />
                        )
                    })}
                </ul>
            )}
            <div className={cn("arrow-icon", {"arrow-icon_active": isSelectOpen, "arrow-icon_invalid": !isValid && !isSelectOpen})}>
                <BottomArrowSvg width={14} height={7} />
            </div>
        </div>
    );
};

Select.propTypes = {
    selectedItem: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired
};

export default Select;